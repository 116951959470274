import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const INIT_PAGE = 0
const INIT_LIMIT = 20
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    searchText: route.query.search || '',
    totalCnt: INIT_TOTAL_CNT,
    list: [],
    initCnt: 0,
    params: computed({
      get: () => {
        return {
          startNum: state.list.length + 1,
          endNum: (state.page + 1) * state.limit,
          searchText: state.searchText
        }
      }
    })
  })

  const fnListFunction = async () => {
    const res = await proxy.$CustomerSvc.postFaq(state.params)
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.totalCnt
      state.list = state.list.concat(
        res.list.map(item => {
          return {
            ...item,
            isShow: false
          }
        })
      )
    }
  }
  const fnSearch = () => {
    router.push({
      name: 'cs-faq-index',
      query: { ...route.query, search: state.searchText }
    })
  }
  const inputSearchText = e => {
    state.searchText = e.target.value
  }

  return { ...toRefs(state), fnListFunction, fnSearch, inputSearchText }
}
